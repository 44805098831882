import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useState } from 'react';
import { shape, arrayOf, bool } from 'prop-types';
import { Text } from '@magalu/stereo-ui/atoms';
import { Star } from '@magalu/stereo-ui-icons';
import ProductReviewGallery from '@magalu/stereo-ui/organisms/ProductReviewGallery';
import ProductReviewPopup from '@magalu/stereo-ui/organisms/ProductReviewPopup';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { ProductReviewContainer, ProductReviewCard, ProductReviewRating } from './ProductReviewMediaCarousel.styles';
import { extractItemId, handleMediaClick } from './utils';
var ProductReviewMediaCarousel = function ProductReviewMediaCarousel(_ref) {
  var _data$productRating, _data$productRating$u;
  var data = _ref.data,
    staticProps = _ref["static"];
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isPopupOpen = _useState2[0],
    setIsPopupOpen = _useState2[1];
  var _useState3 = useState(null),
    _useState4 = _slicedToArray(_useState3, 2),
    selectedMedia = _useState4[0],
    setSelectedMedia = _useState4[1];
  var _useState5 = useState(null),
    _useState6 = _slicedToArray(_useState5, 2),
    review = _useState6[0],
    setReview = _useState6[1];
  var items = (data == null ? void 0 : (_data$productRating = data.productRating) == null ? void 0 : (_data$productRating$u = _data$productRating.userReviews) == null ? void 0 : _data$productRating$u.items) || [];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var isMobile = staticProps.isMobile;
  var mountCondensedReview = function mountCondensedReview() {
    var reviews = [];
    var mediaProps = [];
    items.forEach(function (item) {
      var _item$product, _item$product2;
      var images = (item == null ? void 0 : (_item$product = item.product) == null ? void 0 : _item$product.images) || [];
      var videos = (item == null ? void 0 : (_item$product2 = item.product) == null ? void 0 : _item$product2.videos) || [];
      var medias = images.concat(videos);
      medias == null ? void 0 : medias.forEach(function (media) {
        var mediaPayload = {
          mediaType: media.includes('video') ? 'video' : 'image',
          mediaUrl: media,
          processed: media.includes('processed'),
          thumbnails: media.includes('thumbnails')
        };
        mediaProps.push(mediaPayload);
        var payload = {
          mediaProps: mediaPayload,
          product: _extends({}, media.includes('image') && {
            image: media
          }, media.includes('video') && {
            video: media
          }),
          review: {
            attributes: item.attributes,
            description: item.description,
            rating: item.rating,
            submissionDate: item.submissionDate,
            userData: item.userData
          }
        };
        reviews.push(payload);
      });
    });
    return {
      mediaProps: mediaProps,
      reviews: reviews
    };
  };
  var renderGallery = function renderGallery(thumbnails, _onClick) {
    return React.createElement(ProductReviewGallery, {
      isMobile: isMobile
    }, thumbnails.map(function (elem, key) {
      return React.createElement(ProductReviewCard, {
        key: "product-review-".concat(extractItemId(elem.mediaProps.mediaUrl)),
        onClick: function onClick() {
          return _onClick(key);
        },
        "data-testid": "product-review-card",
        media: {
          mediaType: elem.mediaProps.mediaUrl.includes('video') ? 'video' : 'image',
          mediaUrl: elem.mediaProps.mediaUrl
        },
        width: "121px",
        height: "162px"
      }, React.createElement(ProductReviewRating, null, React.createElement(Star, {
        color: "text.white",
        height: 18,
        width: 18
      }), React.createElement(Text, {
        ml: "8px",
        color: "text.white",
        fontWeight: "bold"
      }, elem.review.rating)));
    }));
  };
  var _mountCondensedReview = mountCondensedReview(),
    reviews = _mountCondensedReview.reviews,
    mediaProps = _mountCondensedReview.mediaProps;
  var thumbnails = reviews.filter(function (item) {
    return item.mediaProps.thumbnails;
  });
  var processed = reviews.filter(function (item) {
    return item.mediaProps.processed;
  });
  var handleClick = function handleClick(key) {
    setIsPopupOpen(true);
    setSelectedMedia(key);
    setReview(processed[key].review);
    handleMediaClick(mediaProps, key, publish);
  };
  var handleChange = function handleChange(item) {
    setReview(processed[item].review);
  };
  return React.createElement(ProductReviewContainer, null, renderGallery(thumbnails, handleClick), isPopupOpen && React.createElement(ProductReviewPopup, {
    review: review,
    mediaProps: mediaProps,
    changeCaroulsel: function changeCaroulsel(item) {
      return handleChange(item);
    },
    charLimit: 120,
    selectedMedia: selectedMedia,
    closePopup: function closePopup() {
      return setIsPopupOpen(false);
    }
  }));
};
process.env.NODE_ENV !== "production" ? ProductReviewMediaCarousel.propTypes = {
  data: shape({
    productRating: shape({
      userReviews: shape({
        items: arrayOf(shape({}))
      })
    })
  }),
  "static": {
    isMobile: bool
  }
} : void 0;
ProductReviewMediaCarousel.defaultProps = {
  data: {
    productRating: {
      userReviews: {
        items: []
      }
    }
  },
  "static": {
    isMobile: false
  }
};
ProductReviewMediaCarousel.ssr = false;
export default withLayoutProps(ProductReviewMediaCarousel);
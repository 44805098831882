import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["label", "labelAs", "titleAs", "marginBottomScroll"];
import React, { useEffect, useRef } from 'react';
import { string, shape, func } from 'prop-types';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import { usePublisher } from '@magalu/mixer-publisher';
import { scrollToRef } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useProductInfo } from '../../../hooks';
import ProductBundleSelector from '../ProductBundleSelector';
import VisibilitySection from '../VisibilitySection';
import RichDescription from '../RichDescription';
import { Content, Description } from './ProductDetail.styles';
var baseTitle = {
  color: 'base',
  fontSize: 2,
  fontWeight: 'bold',
  lineHeight: 'display',
  my: [2, 2, 3, 3]
};
function ProductDetail(_ref) {
  var data = _ref.data,
    onClick = _ref.onClick,
    staticProps = _ref["static"];
  var elemRef = useRef(null);
  var _useProductInfo = useProductInfo(data == null ? void 0 : data.product, {
      getRawDescription: true
    }),
    _useProductInfo$title = _useProductInfo.title,
    title = _useProductInfo$title === void 0 ? '' : _useProductInfo$title,
    _useProductInfo$descr = _useProductInfo.description,
    description = _useProductInfo$descr === void 0 ? '' : _useProductInfo$descr;
  var label = staticProps.label,
    _staticProps$labelAs = staticProps.labelAs,
    labelAs = _staticProps$labelAs === void 0 ? 'h2' : _staticProps$labelAs,
    _staticProps$titleAs = staticProps.titleAs,
    titleAs = _staticProps$titleAs === void 0 ? 'span' : _staticProps$titleAs,
    marginBottomScroll = staticProps.marginBottomScroll,
    props = _objectWithoutProperties(staticProps, _excluded);
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe;
  var handleDetailClickEvent = function handleDetailClickEvent() {
    return scrollToRef(elemRef, marginBottomScroll);
  };
  useEffect(function () {
    subscribe('product:detail:click', onClick || handleDetailClickEvent);
    return function () {
      unsubscribe('product:detail:click', onClick || handleDetailClickEvent);
    };
  });
  return description && React.createElement(VisibilitySection, {
    eventKey: "product:active:section",
    eventValue: "product:detail"
  }, React.createElement(Content, _extends({
    "data-testid": "product-detail",
    ref: elemRef,
    yOffset: marginBottomScroll
  }, props), label && React.createElement(Heading, _extends({}, baseTitle, {
    fontSize: [2, 2, 5, 5],
    fontWeight: "medium",
    "data-testid": "product-detail-label",
    as: labelAs
  }), label), React.createElement(ProductBundleSelector, {
    data: data
  }), React.createElement(Heading, _extends({
    "data-testid": "product-detail-title"
  }, baseTitle, {
    as: titleAs
  }), title), React.createElement(Description, {
    fontSize: [1, 1, 2, 2],
    "data-testid": "product-detail-description"
  }, React.createElement(RichDescription, {
    "static": {
      deniedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'div', 'img', 'svg'],
      richContent: description
    }
  }))));
}
ProductDetail.defaultProps = {
  data: {},
  onClick: null,
  "static": {
    label: 'Informações do produto',
    labelAs: 'h2',
    titleAs: 'h2'
  }
};
process.env.NODE_ENV !== "production" ? ProductDetail.propTypes = {
  data: shape({
    product: shape({
      description: string,
      title: string
    })
  }),
  onClick: func,
  "static": shape({
    label: string,
    labelAs: string,
    titleAs: string
  })
} : void 0;
ProductDetail.ssr = true;
ProductDetail.dataSource = {
  product: {
    fragments: ['includeDescription'],
    query: 'ProductQuery'
  }
};
export default withLayoutProps(ProductDetail);
import React from 'react';
import { shape, string } from 'prop-types';
import { Flex, Link } from '@magalu/stereo-ui/atoms';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import { routePush } from '@magalu/mixer-utils';
import { Image, Title, TextContainer } from './ProductReviewInfoBasic.styles';
var ProductReviewInfoBasic = function ProductReviewInfoBasic(_ref) {
  var _data$product;
  var data = _ref.data,
    staticProps = _ref["static"];
  var link = staticProps.link,
    linkTitle = staticProps.linkTitle;
  var _ref2 = (data == null ? void 0 : data.product) || {},
    image = _ref2.image,
    title = _ref2.title;
  var handleClickLinkInformation = function handleClickLinkInformation(e) {
    e.preventDefault();
    if (link) {
      routePush({
        path: link,
        spa: true
      });
    }
  };
  return React.createElement(Flex, {
    bg: "background.darker",
    p: 3,
    "data-testid": "product-evaluation-container"
  }, !!(data == null ? void 0 : (_data$product = data.product) == null ? void 0 : _data$product.title) && React.createElement(Link, {
    href: link,
    "data-testid": "product-evaluation-content",
    onClick: handleClickLinkInformation
  }, React.createElement(Image, {
    src: image,
    alt: title,
    "data-testid": "product-evaluation-img"
  }), React.createElement(TextContainer, {
    "data-testid": "product-evaluation-section"
  }, React.createElement(Heading, {
    as: "h1",
    fontSize: 3,
    fontStyle: "normal",
    fontWeight: "medium",
    textAlign: "left",
    color: "text.base",
    "data-testid": "product-evaluation-title"
  }, React.createElement(Title, {
    as: "span",
    "data-testid": "product-evaluation-title-link"
  }, linkTitle), title))));
};
ProductReviewInfoBasic.defaultProps = {
  data: {},
  "static": {
    label: '',
    linkTitle: ''
  }
};
process.env.NODE_ENV !== "production" ? ProductReviewInfoBasic.propTypes = {
  data: shape({
    product: shape({
      title: string
    })
  }),
  "static": shape({
    link: string,
    linkTitle: string
  })
} : void 0;
ProductReviewInfoBasic.ssr = true;
export default ProductReviewInfoBasic;
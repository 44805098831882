import React from 'react';
import { shape, arrayOf, string } from 'prop-types';
import Badge from '@magalu/stereo-ui/molecules/Badge';
import Price from '@magalu/stereo-ui/molecules/Price';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useLastViewingProducts } from '../../../hooks';
var ProductPrice = function ProductPrice(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var cashLabelFallback = staticProps.cashLabelFallback;
  var _ref2 = (data == null ? void 0 : data.product) || {},
    badges = _ref2.badges,
    productPrice = _ref2.price,
    installment = _ref2.installment;
  var _ref3 = productPrice || {},
    bestPrice = _ref3.bestPrice,
    fullPrice = _ref3.fullPrice,
    discount = _ref3.discount,
    paymentMethodDescription = _ref3.paymentMethodDescription,
    price = _ref3.price;
  var badge = badges == null ? void 0 : badges.slice(-1)[0];
  useLastViewingProducts((data == null ? void 0 : data.product) || {});
  return React.createElement(React.Fragment, null, !!price && React.createElement("div", {
    "data-testid": "product-price"
  }, React.createElement(Price, {
    price: price,
    fullPrice: fullPrice,
    bestPrice: bestPrice,
    installment: installment,
    paymentMethodDescription: paymentMethodDescription || cashLabelFallback,
    discount: Math.round(discount),
    showDiscount: true,
    isList: false
  })), !!badge && React.createElement(Badge, {
    key: badge.tooltip,
    url: badge.imageUrl,
    tooltip: "selo sobre a promo\xE7\xE3o ".concat(badge.tooltip),
    displayMode: "productPage"
  }));
};
process.env.NODE_ENV !== "production" ? ProductPrice.propTypes = {
  data: shape({
    product: shape({
      badges: arrayOf(shape({})),
      price: shape({})
    })
  }),
  "static": shape({
    cashLabelFallback: string
  })
} : void 0;
ProductPrice.defaultProps = {
  data: {},
  "static": {}
};
ProductPrice.ssr = true;
ProductPrice.dataSource = {
  product: {
    query: 'ProductQuery'
  }
};
export default withLayoutProps(ProductPrice);
import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["label", "labelAs"];
import React from 'react';
import { string, shape } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Grid from '@magalu/stereo-ui/atoms/Grid';
import Button from '@magalu/stereo-ui/atoms/Button';
import Image from '@magalu/stereo-ui/atoms/ResponsiveImage';
import { usePublisher } from '@magalu/mixer-publisher';
import { useProductInfo } from '../../../hooks';
var BUNDLE_CLICK_EVENT = 'product:bundle:click';
var ProductBundleSelector = function ProductBundleSelector(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"],
    layout = _ref.layout;
  var _useProductInfo = useProductInfo(data == null ? void 0 : data.product),
    bundles = _useProductInfo.bundles,
    bundleId = _useProductInfo.bundleId;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var hasBundle = Array.isArray(bundles) && bundles.length > 1;
  var handleBundleClick = function handleBundleClick(id) {
    return function () {
      return publish(BUNDLE_CLICK_EVENT, id);
    };
  };
  var label = staticProps.label,
    labelAs = staticProps.labelAs,
    props = _objectWithoutProperties(staticProps, _excluded);
  return hasBundle && React.createElement(Box, _extends({
    "data-testid": "bundle-selection"
  }, layout, props), React.createElement(Box, {
    as: labelAs,
    lineHeight: "big"
  }, label), React.createElement(Grid, {
    gridAutoFlow: ['row', 'row', 'column', 'column'],
    mt: 3,
    gridGap: 3
  }, bundles.map(function (bundle) {
    return React.createElement(Button, {
      "data-testid": "bundle-option",
      startIcon: React.createElement(Image, {
        width: 80,
        height: 63,
        src: bundle.image,
        alt: "Imagem de ".concat(bundle.title),
        lazy: true,
        maxHeight: "60px",
        mr: 3
      }),
      onClick: handleBundleClick(bundle.id),
      disabled: bundle.id === bundleId,
      centered: true,
      variation: "outline",
      key: "bundle-".concat(bundle.id),
      minHeight: "80px"
    }, "".concat(bundle.title).concat(bundle.reference ? " - ".concat(bundle.reference) : ''));
  })));
};
ProductBundleSelector.defaultProps = {
  data: {},
  layout: {
    py: 3
  },
  "static": {
    label: 'Selecione um dos itens abaixo para visualizar suas especificações:',
    labelAs: 'p'
  }
};
process.env.NODE_ENV !== "production" ? ProductBundleSelector.propTypes = {
  data: shape({
    product: shape({
      description: string,
      title: string
    })
  }),
  layout: shape({}),
  "static": shape({
    label: string,
    labelAs: string
  })
} : void 0;
ProductBundleSelector.dataSource = {
  product: {
    fragments: ['includeFactSheet'],
    query: 'ProductQuery'
  }
};
export default ProductBundleSelector;
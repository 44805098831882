import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import Button from '@magalu/stereo-ui/atoms/Button';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import Image from '@magalu/stereo-ui/atoms/ResponsiveImage';
export var AttachDialog = styled(Dialog)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 90%;\n  height: 85%;\n  max-height: 900px;\n  max-width: 720px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  @media (max-width: 1024px) {\n    height: 75%;\n  }\n\n  @media (max-width: 360px) {\n    height: 90%;\n  }\n\n  @media (max-width: 320px) {\n    height: 96%;\n  }\n"])));
export var LoadingContent = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 4px;\n  width: 100%;\n"])));
export var RotationalImage = styled(Image)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  max-height: 350px;\n  max-width: 300px;\n  transform: rotate(", "deg);\n  margin: 5% auto;\n\n  @media (max-width: 320px) {\n    max-height: 240px;\n    max-width: 240px;\n  }\n"])), function (props) {
  return props.rotation;
});
export var RoundedButton = styled(Button)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  border-radius: 12px;\n"])));
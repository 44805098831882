import React from 'react';
import propTypes from 'prop-types';
import { Heading, Text, Box } from '@magalu/stereo-ui/atoms';
import { StarGroup } from '@magalu/stereo-ui-icons';
import VisibilitySection from '../VisibilitySection';
function ProductQAHighlights(_ref) {
  var _data$productHighligh;
  var staticProps = _ref["static"],
    data = _ref.data,
    id = _ref.id;
  var _ref2 = (_data$productHighligh = data == null ? void 0 : data.productHighlights) !== null && _data$productHighligh !== void 0 ? _data$productHighligh : {},
    highlights = _ref2.highlights;
  var title = staticProps.title,
    aiDescription = staticProps.aiDescription;
  if (highlights === null || !(highlights == null ? void 0 : highlights.length)) {
    return null;
  }
  return React.createElement(VisibilitySection, {
    eventKey: "view_content",
    eventValue: "produto:perguntas"
  }, React.createElement(Box, {
    "data-testid": id,
    id: id,
    maxWidth: "600px",
    mt: 30,
    mb: 50
  }, React.createElement(Heading, {
    as: "h2",
    color: "base",
    fontSize: 5,
    fontWeight: "medium"
  }, title), React.createElement(Box, null, highlights.map(function (_ref3) {
    var summary = _ref3.summary,
      topic = _ref3.topic;
    return React.createElement(Box, {
      key: topic
    }, React.createElement(Heading, {
      as: "h4",
      color: "base",
      fontSize: 2,
      fontWeight: "medium",
      mt: 4,
      mb: 2
    }, topic), React.createElement(Text, {
      lineHeight: 1.25,
      ml: 3,
      mt: 3
    }, summary));
  })), React.createElement(Box, {
    mt: 4,
    display: "flex"
  }, React.createElement(Box, null, React.createElement(StarGroup, {
    fill: "#999"
  })), React.createElement(Box, {
    ml: 2,
    mt: 1
  }, React.createElement(Text, {
    color: "#999",
    fontSize: 1,
    lineHeight: 1.25
  }, aiDescription)))));
}
process.env.NODE_ENV !== "production" ? ProductQAHighlights.propTypes = {
  data: propTypes.shape({
    productHighlights: propTypes.shape({
      highlights: propTypes.arrayOf(propTypes.shape({
        summary: propTypes.string,
        topic: propTypes.string
      }))
    })
  }),
  id: propTypes.string,
  "static": propTypes.shape({
    aiDescription: propTypes.string,
    title: propTypes.string
  })
} : void 0;
ProductQAHighlights.defaultProps = {
  data: {
    productHighlights: {
      highlights: []
    }
  },
  id: '',
  "static": {
    aiDescription: '',
    title: ''
  }
};
ProductQAHighlights.ssr = true;
export default ProductQAHighlights;
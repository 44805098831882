import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { usePublisher } from '@magalu/mixer-publisher';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container, Item, ItemImg, ItemLabel, Heading } from './PopularDepartments.styles';
var PopularDepartments = function PopularDepartments(_ref) {
  var staticProps = _ref["static"];
  var items = staticProps.items,
    title = staticProps.title;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var handleItemClick = function handleItemClick(_ref2) {
    var index = _ref2.index,
      text = _ref2.text;
    publish('product:selectPromotion', {
      index: index,
      text: text
    });
    publish('search:popularDepartaments:click', {
      text: text
    });
  };
  var renderItems = function renderItems() {
    return items.map(function (_ref3, index) {
      var _ref3$link = _ref3.link,
        text = _ref3$link.text,
        href = _ref3$link.href,
        _ref3$image = _ref3.image,
        _ref3$image$src = _ref3$image.src,
        src = _ref3$image$src === void 0 ? '' : _ref3$image$src,
        alt = _ref3$image.alt,
        imageWidth = _ref3$image.imageWidth,
        imageHeight = _ref3$image.imageHeight,
        id = _ref3.id;
      publish('product:viewPromotion', {
        index: index,
        text: text
      });
      var imageIms = "".concat(imageWidth !== null && imageWidth !== void 0 ? imageWidth : '90', "x").concat(imageHeight !== null && imageHeight !== void 0 ? imageHeight : '90');
      var imageSrc = "".concat(src, "?ims=").concat(imageIms.replace(/px/gi, ''));
      return React.createElement(Item, {
        key: id,
        onClick: function onClick() {
          return handleItemClick({
            index: index,
            text: text
          });
        },
        href: href,
        "data-testid": "item-popular-departments"
      }, React.createElement(ItemImg, {
        alt: alt,
        src: imageSrc,
        imageWidth: imageWidth,
        imageHeight: imageHeight,
        lazy: true
      }), React.createElement(ItemLabel, null, text));
    });
  };
  return React.createElement(React.Fragment, null, React.createElement(Heading, {
    "data-testid": "popular-departments-title",
    as: "h2"
  }, title), React.createElement(Container, null, renderItems()));
};
PopularDepartments.defaultProps = {
  "static": {
    items: []
  }
};
process.env.NODE_ENV !== "production" ? PopularDepartments.propTypes = {
  "static": shape({
    items: arrayOf(shape({}))
  })
} : void 0;
PopularDepartments.ssr = true;
export default withLayoutProps(PopularDepartments);
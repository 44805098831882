import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import ResponsiveImage from '@magalu/stereo-ui/atoms/ResponsiveImage';
import Text from '@magalu/stereo-ui/atoms/Text';
import Flex from '@magalu/stereo-ui/atoms/Flex';
export var Container = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    bg: 'background.lighter',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100px;\n"])));
export var Image = styled(ResponsiveImage).attrs(function (props) {
  return {
    imageHeight: props.imageHeight || '79px',
    imageWidth: props.imageWidth || '79px',
    ml: '10px'
  };
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  max-width: 100%;\n"])));
export var TextContainer = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginLeft: 2,
    textAlign: 'left'
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var Title = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 1,
    fontStyle: 'normal',
    lineHeight: 'display',
    marginTop: 2,
    maxWidth: '100%'
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
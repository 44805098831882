import _extends from "@babel/runtime/helpers/esm/extends";
import React from 'react';
import { shape, arrayOf, oneOfType, string } from 'prop-types';
import { textEllipsis } from '@magalu/mixer-utils';
import { Container, Title, Image, Label, Link } from './Outdoor.styles';
var Outdoor = function Outdoor(_ref) {
  var staticProps = _ref["static"];
  var title = staticProps.title,
    content = staticProps.content,
    button = staticProps.button;
  return React.createElement(Container, {
    "data-testid": "outdoor"
  }, !!Object.keys(title).length && React.createElement(Title, title.layout || {}, title.text), content && content.map(function (line) {
    if (typeof line === 'string') {
      return React.createElement(Label, {
        key: "outdoor-".concat(line)
      }, line);
    }
    if (line.img) {
      return React.createElement(Image, {
        key: "outdoor-".concat(line.img),
        src: line.img,
        alt: line.alt
      });
    }
    return React.createElement(Label, _extends({}, line.layout || {}, {
      key: "outdoor-".concat(line.text)
    }), line.ellipsis ? textEllipsis(line.text, line.ellipsis) : line.text);
  }), !!Object.keys(button).length && React.createElement(Link, _extends({
    href: button.href
  }, button.layout || {}), button.text));
};
Outdoor.defaultProps = {
  "static": {
    button: {},
    content: [],
    title: {}
  }
};
process.env.NODE_ENV !== "production" ? Outdoor.propTypes = {
  "static": shape({
    button: shape({}),
    content: arrayOf(oneOfType([shape({}), string])),
    title: shape({}).isRequired
  })
} : void 0;
Outdoor.ssr = true;
export default Outdoor;
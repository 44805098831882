import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import styled from 'styled-components';
import ResponsiveImage from '@magalu/stereo-ui/atoms/ResponsiveImage';
import Text from '@magalu/stereo-ui/atoms/Text';
import Flex from '@magalu/stereo-ui/atoms/Flex';
export var Container = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    bg: 'background.lighter',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    flexDirection: 'column',
    my: [24, 18]
  }, props);
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  max-width: 320px;\n"])));
export var Image = styled(ResponsiveImage)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  max-width: 100%;\n  height: 152px;\n  width: 216px;\n"])));
export var TextContainer = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    flexDirection: 'column'
  }, props);
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral([""])));
export var Title = styled(Text).attrs(function (props) {
  return _extends({
    fontSize: 3,
    fontStyle: 'normal',
    fontWeight: 'normal',
    maxWidth: '100%',
    mt: 3,
    textAlign: 'left'
  }, props);
})(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  line-height: 24px;\n"])));
import React from 'react';
import { shape, string } from 'prop-types';
import Link from '@magalu/stereo-ui/atoms/Link';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { routePush } from '@magalu/mixer-utils';
import { Container, Image, Title, TextContainer } from './ProductReviewInfo.styles';
var ProductReviewInfo = function ProductReviewInfo(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var _ref2 = (data == null ? void 0 : data.product) || {},
    image = _ref2.image,
    title = _ref2.title;
  var link = staticProps.link;
  var handleClickLink = function handleClickLink(e) {
    e.preventDefault();
    routePush({
      path: link,
      spa: true
    });
  };
  return React.createElement(Container, {
    "data-testid": "product-evaluation-container"
  }, React.createElement(Link, {
    "data-testid": "product-evaluation-pdp-link",
    href: link,
    onClick: handleClickLink,
    flexDirection: "column"
  }, React.createElement(Image, {
    src: image,
    alt: title,
    "data-testid": "product-evaluation-img"
  }), React.createElement(TextContainer, {
    "data-testid": "product-evaluation-section"
  }, React.createElement(Title, {
    color: "black",
    lineHeight: "display",
    "data-testid": "product-evaluation-title"
  }, title))));
};
ProductReviewInfo.defaultProps = {
  data: {},
  "static": {
    link: ''
  }
};
process.env.NODE_ENV !== "production" ? ProductReviewInfo.propTypes = {
  data: shape({
    product: shape({
      title: string
    })
  }),
  "static": shape({
    link: string
  })
} : void 0;
ProductReviewInfo.ssr = true;
export default withLayoutProps(ProductReviewInfo);
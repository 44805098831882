import React from 'react';
import { string } from 'prop-types';
var Product = function Product(_ref) {
  var title = _ref.title;
  return React.createElement("h1", null, title);
};
Product.initialData = {
  fragments: ['factsheet'],
  product: 'product-query'
};
process.env.NODE_ENV !== "production" ? Product.propTypes = {
  title: string.isRequired
} : void 0;
export default Product;
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from 'styled-components';
import { Heading as H } from '@magalu/stereo-ui/atoms';
import Image from '@magalu/stereo-ui/atoms/ResponsiveImage';
import { getTextColor, themeGet, getMediaQuery } from '@magalu/stereo-ui-styles';
export var Container = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  overflow-x: auto;\n\n  ", " {\n    font-size: ", "px;\n    overflow-x: hidden;\n  }\n"])), getMediaQuery('medium'), themeGet('fontSizes.2'));
export var Heading = styled(H)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  margin: 0 8px 30px;\n\n  ", " {\n    font-size: ", "px;\n  }\n"])), themeGet('fontSizes.2'), getMediaQuery('medium'), themeGet('fontSizes.5'));
export var Item = styled.a(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin: 0px 5px 0px 5px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  cursor: pointer;\n"])));
export var ItemImg = styled(Image)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  border-radius: 150px;\n  background-color: ", ";\n  width: ", ";\n  height: ", ";\n"])), function (props) {
  return props.bgColor;
}, function (_ref) {
  var imageWidth = _ref.imageWidth;
  return imageWidth || '90px';
}, function (_ref2) {
  var imageHeight = _ref2.imageHeight;
  return imageHeight || '90px';
});
export var ItemLabel = styled.p(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  margin-top: 15px;\n  text-align: center;\n  width: 90px;\n  line-height: ", ";\n  color: ", ";\n"])), function (props) {
  return themeGet('lineHeights.display')(props);
}, function (props) {
  return getTextColor('base')(props);
});
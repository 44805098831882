import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/esm/extends";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
var _excluded = ["asTab", "title", "marginBottomScroll"];
import React, { useRef, useEffect } from 'react';
import { arrayOf, shape, func } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import StereoPaymentMethods from '@magalu/stereo-ui/molecules/PaymentMethods';
import { usePublisher } from '@magalu/mixer-publisher';
import { scrollToRef } from '@magalu/mixer-utils';
import VisibilitySection from '../VisibilitySection';
var PaymentMethods = function PaymentMethods(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"],
    layout = _ref.layout,
    onEvent = _ref.onEvent;
  var elemRef = useRef(null);
  var _usePublisher = usePublisher(),
    subscribe = _usePublisher.subscribe,
    unsubscribe = _usePublisher.unsubscribe,
    publish = _usePublisher.publish;
  var _ref2 = (data == null ? void 0 : data.product) || {},
    _ref2$paymentMethods = _ref2.paymentMethods,
    paymentMethods = _ref2$paymentMethods === void 0 ? [] : _ref2$paymentMethods,
    _ref2$installment = _ref2.installment,
    installment = _ref2$installment === void 0 ? [] : _ref2$installment;
  var Enum = _defineProperty({
    boleto: 'bankSlip',
    luiza_ouro: 'luizaCard',
    pix: 'pix'
  }, installment.paymentMethodId, 'creditCard');
  var paymentsById = paymentMethods.reduce(function (acc, payment) {
    return _extends({}, acc, _defineProperty({}, Enum[payment.id], payment));
  }, {});
  var handlePaymentMethodChange = function handlePaymentMethodChange(_ref3) {
    var id = _ref3.id;
    publish('product:paymentmethod:change', {
      paymentId: id
    });
  };
  var asTab = staticProps.asTab,
    title = staticProps.title,
    marginBottomScroll = staticProps.marginBottomScroll,
    props = _objectWithoutProperties(staticProps, _excluded);
  var handlePaymentMethodClick = function handlePaymentMethodClick() {
    return onEvent ? onEvent() : scrollToRef(elemRef, marginBottomScroll);
  };
  useEffect(function () {
    subscribe('product:paymentmethod:click', handlePaymentMethodClick);
    return function () {
      unsubscribe('product:paymentmethod:click', handlePaymentMethodClick);
    };
  });
  return !!paymentMethods.length && React.createElement(VisibilitySection, {
    eventKey: "product:active:section",
    eventValue: "product:paymentmethod"
  }, React.createElement(Box, _extends({
    "data-testid": "payment-methods",
    mb: 3
  }, props, layout, {
    ref: elemRef,
    yOffset: marginBottomScroll
  }), title && React.createElement(Heading, {
    "data-testid": "payment-methods-title",
    as: "h2",
    color: "base",
    fontSize: 5,
    fontWeight: "medium",
    my: [2, 2, 3, 3]
  }, title), React.createElement(StereoPaymentMethods, {
    asTab: asTab,
    payments: paymentsById,
    onChange: handlePaymentMethodChange
  })));
};
process.env.NODE_ENV !== "production" ? PaymentMethods.propTypes = {
  data: shape({
    product: shape({
      installment: shape({}),
      paymentMethods: arrayOf(shape({}))
    })
  }),
  layout: shape({}),
  onEvent: func,
  "static": shape({})
} : void 0;
PaymentMethods.defaultProps = {
  data: {},
  layout: {},
  onEvent: undefined,
  "static": {
    asTab: true
  }
};
PaymentMethods.ssr = true;
PaymentMethods.dataSource = {
  product: {
    fragments: ['showPayment'],
    query: 'ProductQuery'
  }
};
export default PaymentMethods;
import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["label", "variant", "error"];
import React, { forwardRef } from 'react';
import { string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { Flex, SelectRating, Text } from '@magalu/stereo-ui/atoms';
var RatingInput = forwardRef(function (_ref, ref) {
  var label = _ref.label,
    variant = _ref.variant,
    error = _ref.error,
    props = _objectWithoutProperties(_ref, _excluded);
  var variants = {
    "default": {
      alignItems: 'center',
      flexDirection: 'row',
      fontSize: [2, 4],
      fontWeight: 'regular',
      gap: [3, 4],
      iconSize: [24, 40]
    },
    large: {
      alignItems: ['flex-start', 'center'],
      flexDirection: ['column', 'row'],
      fontSize: [3, 5],
      fontWeight: 'medium',
      gap: [3, 0],
      iconSize: [40, 48]
    }
  };
  var _variants$variant = variants[variant],
    alignItems = _variants$variant.alignItems,
    flexDirection = _variants$variant.flexDirection,
    fontSize = _variants$variant.fontSize,
    fontWeight = _variants$variant.fontWeight,
    gap = _variants$variant.gap,
    iconSize = _variants$variant.iconSize;
  return React.createElement(Flex, {
    flexDirection: "column",
    ref: ref,
    "data-testid": "rating-input"
  }, React.createElement(Flex, {
    width: 1,
    justifyContent: "space-between",
    alignItems: alignItems,
    flexDirection: flexDirection,
    gap: gap
  }, React.createElement(Text, {
    lineHeight: "fontSize",
    fontSize: fontSize,
    fontWeight: fontWeight,
    "data-testid": "rating-input-label"
  }, label), React.createElement(SelectRating, _extends({
    height: iconSize,
    width: iconSize,
    emptyOutline: true,
    "data-testid": "rating-input-stars"
  }, props))), error && React.createElement(Flex, {
    mt: 1,
    alignItems: "center",
    gap: 2,
    "data-testid": "rating-input-error",
    role: "alert"
  }, React.createElement(Icon, {
    name: "InfoCircle",
    color: "alert.danger.text",
    width: [13, 20],
    height: [13, 20]
  }), React.createElement(Text, {
    fontSize: [1, 2],
    color: "alert.danger.text"
  }, error)));
});
RatingInput.displayName = 'RatingInput';
RatingInput.defaultProps = {
  error: '',
  label: '',
  variant: 'default'
};
process.env.NODE_ENV !== "production" ? RatingInput.propTypes = {
  error: string,
  label: string,
  variant: string
} : void 0;
export default RatingInput;
import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["label", "imageWidth", "imageHeight", "fontSize", "showFullTitle"];
import React from 'react';
import { shape, string } from 'prop-types';
import Heading from '@magalu/stereo-ui/atoms/Heading';
import { getFullTitle } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container, Image, Title, TextContainer } from './ProductCard.styles';
var ProductCard = function ProductCard(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var label = staticProps.label,
    imageWidth = staticProps.imageWidth,
    imageHeight = staticProps.imageHeight,
    fontSize = staticProps.fontSize,
    showFullTitle = staticProps.showFullTitle,
    props = _objectWithoutProperties(staticProps, _excluded);
  var _ref2 = (data == null ? void 0 : data.product) || {},
    image = _ref2.image,
    title = _ref2.title;
  var fullTitle = showFullTitle && title && getFullTitle(data == null ? void 0 : data.product);
  return React.createElement(Container, _extends({
    "data-testid": "product-card-container"
  }, props), React.createElement(Image, {
    imageWidth: imageWidth,
    imageHeight: imageHeight,
    src: image,
    alt: title,
    "data-testid": "product-card-img"
  }), React.createElement(TextContainer, {
    "data-testid": "product-card-section"
  }, label && React.createElement(Heading, {
    as: "h2",
    color: "black",
    fontSize: 3,
    lineHeight: "display",
    fontWeight: "medium",
    "data-testid": "product-card-label"
  }, label), title && React.createElement(Title, {
    color: "black",
    fontSize: fontSize,
    "data-testid": "product-card-title"
  }, fullTitle || title)));
};
ProductCard.defaultProps = {
  data: {},
  "static": {
    label: ''
  }
};
process.env.NODE_ENV !== "production" ? ProductCard.propTypes = {
  data: shape({
    product: shape({
      title: string
    })
  }),
  "static": shape({
    label: string
  })
} : void 0;
ProductCard.dataSource = {
  product: {
    fragments: ['includeDescription'],
    query: 'ProductQuery'
  }
};
ProductCard.ssr = true;
export default withLayoutProps(ProductCard);
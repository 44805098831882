import _extends from "@babel/runtime/helpers/esm/extends";
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3;
import styled from 'styled-components';
import ResponsiveImage from '@magalu/stereo-ui/atoms/ResponsiveImage';
import Text from '@magalu/stereo-ui/atoms/Text';
import Flex from '@magalu/stereo-ui/atoms/Flex';
export var Image = styled(ResponsiveImage)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100px;\n  width: 100px;\n  border-radius: 8px;\n"])));
export var TextContainer = styled(Flex).attrs(function (props) {
  return _extends({
    alignItems: 'center',
    flexDirection: 'column',
    lineHeight: '22px'
  }, props);
})(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin-left: 16px;\n  padding-top: 8px;\n"])));
export var Title = styled(Text)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: block;\n"])));
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import styled from 'styled-components';
import Button from '@magalu/stereo-ui/atoms/Button';
import Dialog from '@magalu/stereo-ui/atoms/Dialog';
import Image from '@magalu/stereo-ui/atoms/ResponsiveImage';
import { getBackgroundColor } from '@magalu/stereo-ui-styles';
export var AttachDialog = styled(Dialog)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 90%;\n  height: 90%;\n  max-width: 720px;\n  max-height: 640px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n"])));
export var AttachedImage = styled(Image)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border-radius: 18px;\n  object-fit: cover;\n  height: 150px;\n  width: 150px;\n"])));
export var AttachedVideo = styled.video(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  border-radius: 18px;\n  height: 150px;\n  object-fit: cover;\n  width: 150px;\n"])));
export var AttachmentGaleryContainer = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  overflow-x: scroll;\n  margin-top: 20px;\n"])));
export var AttachmentGaleryItem = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: nowrap;\n  position: relative;\n  flex-direction: reverse-row;\n"])));
export var AttachmentGaleryList = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: flex;\n  gap: 10px;\n"])));
export var DeleteAttachment = styled.button(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  border: none;\n  border-radius: 50%;\n  display: flex;\n  position: absolute;\n  right: 3%;\n  top: 5%;\n  cursor: pointer;\n"])), getBackgroundColor('transparent'));
export var RoundedButton = styled(Button)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  border-radius: 12px;\n"])));
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
export var extractItemId = function extractItemId(mediaUrl) {
  var _mediaUrl$split$rever = mediaUrl.split('/').reverse()[0].split('.'),
    _mediaUrl$split$rever2 = _slicedToArray(_mediaUrl$split$rever, 1),
    itemId = _mediaUrl$split$rever2[0];
  return itemId;
};
export var handleMediaClick = function handleMediaClick(mediaProps, key, publish) {
  var processedMedia = mediaProps.filter(function (media) {
    return media.processed;
  });
  var selectedMedia = processedMedia[key];
  if (selectedMedia.mediaType === 'image') {
    publish('product:review:condensedMedia:image:click');
  } else {
    publish('product:review:condensedMedia:video:click');
  }
};
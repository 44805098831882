import _extends from "@babel/runtime/helpers/esm/extends";
var buildCommonParams = function buildCommonParams(_ref) {
  var _structure$route, _data$search, _data$search$products, _data$search2, _data$search2$product;
  var structure = _ref.structure,
    data = _ref.data;
  return {
    searchPage: ((_structure$route = structure.route) == null ? void 0 : _structure$route.page) || 1,
    searchProducts: (_data$search = data.search) == null ? void 0 : (_data$search$products = _data$search.products) == null ? void 0 : _data$search$products.map(function (_ref2) {
      var variationId = _ref2.variationId;
      return variationId;
    }).join(','),
    searchResults: ((_data$search2 = data.search) == null ? void 0 : (_data$search2$product = _data$search2.products) == null ? void 0 : _data$search2$product.length) || 0
  };
};
var buildCustomParams = function buildCustomParams(_ref3) {
  var _structure$route2, _structure$route3;
  var type = _ref3.type,
    _ref3$structure = _ref3.structure,
    structure = _ref3$structure === void 0 ? {} : _ref3$structure,
    _ref3$data = _ref3.data,
    data = _ref3$data === void 0 ? {} : _ref3$data;
  var customParam = {
    search: _extends({
      search: "".concat((_structure$route2 = structure.route) == null ? void 0 : _structure$route2.term)
    }, buildCommonParams({
      data: data,
      structure: structure
    })),
    selection: _extends({
      search: "LANDING PAGE: ".concat((_structure$route3 = structure.route) == null ? void 0 : _structure$route3.selectionId)
    }, buildCommonParams({
      data: data,
      structure: structure
    }))
  };
  return customParam[type || structure.id] || {};
};
export default buildCustomParams;
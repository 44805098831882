import { slug } from '@magalu/mixer-utils';
var parseWidgetData = function parseWidgetData(product, position) {
  var _product$customFields, _product$customFields2, _product$customFields3, _product$customFields4, _product$categories, _product$installment, _product$installment$, _product$installment2, _product$installment3, _product$installment4, _product$installment5, _product$salePrice, _product$discount, _product$price, _product$customFields5, _product$customFields6, _product$customFields7, _product$customFields8, _product$categories2;
  return {
    available: true,
    brand: {
      label: product.brand,
      slug: slug(product.brand)
    },
    category: {
      id: (_product$customFields = product.customFields) == null ? void 0 : (_product$customFields2 = _product$customFields.categoriesId) == null ? void 0 : (_product$customFields3 = _product$customFields2.split('/')) == null ? void 0 : (_product$customFields4 = _product$customFields3[0]) == null ? void 0 : _product$customFields4.toUpperCase(),
      name: (_product$categories = product.categories) == null ? void 0 : _product$categories[0]
    },
    id: product.id,
    image: product.imageLink,
    installment: {
      amount: (_product$installment = product.installment) == null ? void 0 : (_product$installment$ = _product$installment.amount) == null ? void 0 : _product$installment$.value,
      quantity: (_product$installment2 = product.installment) == null ? void 0 : _product$installment2.months,
      totalAmount: (((_product$installment3 = product.installment) == null ? void 0 : _product$installment3.months) * Number((_product$installment4 = product.installment) == null ? void 0 : (_product$installment5 = _product$installment4.amount) == null ? void 0 : _product$installment5.value)).toFixed(2)
    },
    origin: 'percycle',
    position: position,
    price: {
      bestPrice: (_product$salePrice = product.salePrice) == null ? void 0 : _product$salePrice.value,
      discount: product == null ? void 0 : (_product$discount = product.discount) == null ? void 0 : _product$discount.value,
      price: (_product$price = product.price) == null ? void 0 : _product$price.value
    },
    px: product.px,
    seller: {
      category: product.seller === 'magazineluiza' ? '1p' : '3p',
      id: product.seller
    },
    subcategory: {
      id: (_product$customFields5 = product.customFields) == null ? void 0 : (_product$customFields6 = _product$customFields5.categoriesId) == null ? void 0 : (_product$customFields7 = _product$customFields6.split('/')) == null ? void 0 : (_product$customFields8 = _product$customFields7[1]) == null ? void 0 : _product$customFields8.toUpperCase(),
      name: (_product$categories2 = product.categories) == null ? void 0 : _product$categories2[1]
    },
    title: product.title,
    url: product.link
  };
};
var parsePercycleWidgets = function parsePercycleWidgets(widgets) {
  return widgets == null ? void 0 : widgets.map(function (_ref) {
    var widget = _ref.widget,
      px = _ref.px,
      data = _ref.data;
    return {
      data: data == null ? void 0 : data.map(function (product, position) {
        return parseWidgetData(product, position + 1);
      }),
      px: px,
      widget: widget
    };
  });
};
export default parsePercycleWidgets;
import React from 'react';
import { arrayOf, shape } from 'prop-types';
import Box from '@magalu/stereo-ui/atoms/Box';
import { getFactsheetValueFromSlug } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { useProductInfo } from '../../../hooks';
var iframeRegexp = /<iframe [^<]*(<\/iframe>|\/>)/g;
var removeNonIframeHtml = function removeNonIframeHtml(data) {
  var _data$match, _data$match$join;
  return ((_data$match = data.match(iframeRegexp)) == null ? void 0 : (_data$match$join = _data$match.join('')) == null ? void 0 : _data$match$join.replace(/http:\/\//g, 'https://')) || null;
};
var ProductRichContent = function ProductRichContent(_ref) {
  var data = _ref.data;
  var _useProductInfo = useProductInfo(data == null ? void 0 : data.product),
    factsheet = _useProductInfo.factsheet;
  var richContent = removeNonIframeHtml(getFactsheetValueFromSlug(factsheet, 'destaques') || '');
  return !!richContent && React.createElement(Box, {
    textAlign: "center",
    dangerouslySetInnerHTML: {
      __html: "".concat(richContent)
    }
  });
};
ProductRichContent.defaultProps = {
  data: {
    product: {}
  }
};
process.env.NODE_ENV !== "production" ? ProductRichContent.propTypes = {
  data: shape({
    product: shape({
      factsheet: arrayOf(shape({}))
    })
  })
} : void 0;
ProductRichContent.dataSource = {
  product: {
    fragments: ['includeFactSheet']
  }
};
ProductRichContent.ssr = true;
export default withLayoutProps(ProductRichContent);
import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject;
import styled from 'styled-components';
import StereoText from '@magalu/stereo-ui/atoms/Text';
export var Text = styled(StereoText).attrs(function () {
  return {
    fontSize: [4, 4, 5, 5],
    lineHeight: 'input',
    overflow: 'hidden'
  };
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  flex: 1;\n"])));
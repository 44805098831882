import _taggedTemplateLiteral from "@babel/runtime/helpers/esm/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import styled from 'styled-components';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Text from '@magalu/stereo-ui/atoms/Text';
import { themeGet, getPaletteColor, getTextColor } from '@magalu/stereo-ui-styles';
export var Container = styled(Flex).attrs({
  alignItems: 'center',
  flexDirection: 'column'
})(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 30px 5px;\n  background-color: ", ";\n  justify-content: center;\n"])), function (props) {
  return getPaletteColor('background.lighter')(props);
});
export var Image = styled.img(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 20px 0;\n"])));
export var Label = styled(Text).attrs(function (props) {
  return {
    color: props.color || 'text.base',
    fontSize: props.fontSize || 1,
    fontWeight: props.fontWeight || 'regular'
  };
})(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-stretch: normal;\n  font-style: normal;\n  line-height: 1.58;\n  letter-spacing: normal;\n  text-align: center;\n  text-transform: ", ";\n  width: ", ";\n"])), function (props) {
  return props.textTransform || 'none';
}, function (props) {
  return props.width || '100%';
});
export var Link = styled.a(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: ", ";\n  font-size: ", "px;\n  text-transform: uppercase;\n  color: ", ";\n  width: 238px;\n  height: 40px;\n  border-radius: 20px;\n  background-color: ", ";\n  cursor: pointer;\n  margin-top: 10px;\n"])), function (props) {
  return themeGet('fontWeights.regular')(props);
}, function (props) {
  return themeGet('fontSizes.0')(props);
}, function (props) {
  return getTextColor('lightest')(props);
}, function (props) {
  return getPaletteColor('primary.base')(props);
});
export var Title = styled(Text).attrs(function (props) {
  return {
    color: props.color || 'primary.base',
    fontSize: props.fontSize || 8,
    fontWeight: props.fontWeight || 'bold'
  };
})(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-stretch: normal;\n  font-style: normal;\n  line-height: normal;\n"])));
import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["error", "onChange", "rating", "value"];
import React, { forwardRef } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { Icon } from '@magalu/stereo-ui-icons';
import { Flex, Input, Text } from '@magalu/stereo-ui/atoms';
var RatingComment = forwardRef(function (_ref, ref) {
  var error = _ref.error,
    onChange = _ref.onChange,
    rating = _ref.rating,
    value = _ref.value,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Flex, {
    maxWidth: "568px",
    flexDirection: "column",
    ref: ref,
    "data-testid": "rating-comment"
  }, React.createElement(Text, {
    mb: [3, 4],
    "data-testid": "rating-comment-label",
    lineHeight: "input",
    fontWeight: "medium",
    fontSize: [2, 5]
  }, "O que os clientes precisam saber antes de comprar?", rating > 3 && React.createElement(Text, {
    ml: [1, 0],
    as: "span",
    fontWeight: "regular",
    color: "lighter",
    fontSize: [1, 4]
  }, "Opcional")), React.createElement(Input, _extends({}, props, {
    "data-testid": "rating-comment-input",
    multiline: true,
    rows: "6",
    onChange: onChange,
    placeholder: "O que voc\xEA gostou e o que n\xE3o gostou no produto?",
    value: value
  })), !error.valid && React.createElement(Flex, {
    mt: 3,
    alignItems: "center",
    gap: 2,
    "data-testid": "rating-comment-error",
    role: "alert"
  }, React.createElement(Icon, {
    name: "InfoCircle",
    color: "alert.danger.text",
    width: 20,
    height: 20
  }), React.createElement(Text, {
    fontSize: [1, 2],
    color: "alert.danger.text"
  }, error.message)), error.valid && React.createElement(Flex, {
    mt: 3,
    alignItems: "center",
    gap: 2,
    "data-testid": "rating-comment-notification",
    role: "alert"
  }, React.createElement(Text, {
    fontSize: [1, 2],
    color: error.textColor
  }, error.message)));
});
RatingComment.displayName = 'RatingInput';
RatingComment.defaultProps = {
  error: {
    message: '',
    valid: true
  },
  onChange: function onChange() {},
  rating: 0,
  value: ''
};
process.env.NODE_ENV !== "production" ? RatingComment.propTypes = {
  error: shape({
    message: string,
    valid: bool
  }),
  onChange: func,
  rating: number,
  value: string
} : void 0;
export default RatingComment;
import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["children"];
import React from 'react';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container } from './styles';
function Panel(_ref) {
  var children = _ref.children,
    props = _objectWithoutProperties(_ref, _excluded);
  return React.createElement(Container, props, children);
}
Panel.ssr = true;
export default withLayoutProps(Panel);